.productpage .pc1 {
    padding-top: 5%;
    display: flex;
    justify-content: center;
    position: relative;
}

.productpage .pc1 .c11 {
    display: flex;
    justify-content: flex-start;
    width: 30%;
}

.productpage .pc1 .c11 .imgset {
    width: 10%;
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
}

.productpage .pc1 .c11 .imgset img {
    width: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
}


.productpage .pc1 .c11 .imgset .active {
    border: 5px solid var(--col1);
    width: 95%;
}

.productpage .pc1 .c11 .imgbig {
    width: 70%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    aspect-ratio: 1/1;
}

.productpage .pc1 .c12 {
    display: flex;
    width: 40%;
    flex-direction: column;
}


.productpage .pc1 .c12 .c121 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.productpage .pc1 .c12 .price {
    font-size: 2rem;
    font-weight: 600;
    color: var(--col1);
    display: flex;
    align-items: center;
}


.productpage .pc1 .c12 .price span {
    font-size: 20px;
    font-weight: 400;
    color: gray;
    text-decoration: line-through;
    margin-left: 10px;
}


.productpage .pc1 .c12 .incrdecr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 150px;
    border: 1px solid var(--col1);
    border-radius: 5px;
}

.productpage .pc1 .c12 .incrdecr button {
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-color: var(--col1);
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
}

.productpage .pc1 .c12 .incrdecr p {
    font-size: 25px;
    margin: 0px;
    padding: 0px;
}

.productpage .btncont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}

.productpage .btncont button {
    width: 200px;
    height: 50px;
    border: none;
    background-color: var(--col1);
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}


.productpage .btncont button:hover {
    background-color: black;
}



/*  */
.productpage .pc2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: aqua; */
}

.productpage .pc2 .tabs {
    display: flex;
    margin: 20px 0px;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.productpage .pc2 .tabs .active {
    border: none;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--col1);
    cursor: pointer;
    border-bottom: 2px solid var(--col1);
    margin: 0px 10px;
    background-color: white;
}

.productpage .pc2 .tabs .inactive {
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: gray;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin: 0px 10px;
    background-color: white;
}

.productpage .reviewcont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 30px;
}

.productpage form {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
    width: 70%;
    max-width: 700px;
}

.productpage form .fromgroup {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
}

.productpage .reviewcont form div label {
    font-size: 15px;
    font-weight: 600;
    color: var(--col1);
}

.productpage .reviewcont form div input {
    width: 100%;
    height: 40px;
    border: 1px solid var(--col1);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
}



.productpage .reviewcont form div textarea {
    width: 100%;
    min-height: 100px;
    border: 1px solid var(--col1);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
}


.productpage .reviewcont button {
    width: 200px;
    height: 50px;
    border: none;
    background-color: var(--col1);
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin: 20px 0px;
}

.productpage .allreview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    max-width: 700px;
    margin-top: 50px;

}

.productpage .allreview .head1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--col1);
    font-weight: 300;
    font-size: 25px;
    color: var(--col1);
}

.productpage .allreview .review {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0px;
}

.productpage .reviewhead {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0px;
    position: relative;
}
.productpage .reviewhead p{
    margin: 0px;
    padding: 0px;
    font-size: 18px;
    color: var(--col1);
    position: absolute;
    left: 0px;
}
.productpage .reviewhead span {
    background-color: var(--col1);
    margin: 0px;
    padding: 5px 10px;
    border-radius: 20px;
    color: white;
    margin-left: 10px;
}

.productpage .reviewbody{
    font-size: 14px;
}

.productpage .desc{
    width: 70%;
    max-width: 900px;
    align-self: center;
}

.productpage .rating{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0px;
}

.productpage .rating .starinactive{
    color: lightgray;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.productpage .rating .starinactive:hover{
    color: var(--col1);
}


.productpage .rating .staractive{
    color: gold;
    width: 40px;
    height: 40px;
    cursor: pointer;
    fill: gold;
}



.productpage .rating1{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0px;
}

.productpage .rating1 .starinactive{
    color: lightgray;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.productpage .rating1 .starinactive:hover{
    color: var(--col1);
}


.productpage .rating1 .staractive{
    color: gold;
    width: 20px;
    height: 20px;
    cursor: pointer;
    fill: gold;
}

@media only screen and (max-width: 768px) {
    .productpage .pc1 {
        flex-direction: column;
        align-items: center;
    }
    .productpage .pc1 .c11 {
        width: 80%;
        /* background-color: aqua; */
        align-items: center;
        flex-direction: column-reverse;
    }
    .productpage .pc1 .c11 .imgset {
        max-width: 300px;
        width: 100%;
        margin: 30px 5px;
        /* background-color: aqua; */
        flex-direction: row;
    }
    .productpage .pc1 .c12 {
        width: 80%;
    }
    .productpage form {
        width: 100%;
    }

    .productpage form .fromgroup {
        display: flex;
        flex-direction: column;
        margin: 5px 20px;
    }
    .productpage .reviewcont form div label {
        font-size: 15px;
        font-weight: 600;
        color: var(--col1);
    }

    .productpage .reviewcont form div input {
        width: 100%;
        height: 40px;
        border: 1px solid var(--col1);
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 10px;
    }

    .productpage .reviewcont form div textarea {
        width: 100%;
        height: 50px;
        border: 1px solid var(--col1);
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 10px;
    }

    .productpage .reviewcont form button {
        width: 200px;
        height: 50px;
        border: none;
        background-color: var(--col1);
        color: white;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        margin: 20px 0px;
        align-self: center;
    }

    .productpage .allreview {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 900px;
        margin-top: 50px;
    }

    .productpage .allreview .head1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        border-bottom: 2px solid var(--col1);
        font-weight: 300;
        font-size: 20px;
        color: var(--col1);
    }

    
    .productpage .allreview .review {
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 20px;
        margin: 20px 0px;
    }
    .productpage .reviewhead {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        align-items: center;
        margin: 10px 0px;
        position: relative;
    }

    .productpage .reviewhead p {
        margin: 0px;
        padding: 0px;
        font-size: 18px;
        color: var(--col1);
        position: relative
    }

    .productpage .reviewhead span {
        background-color: var(--col1);
        margin: 0px;
        padding: 2px 10px;
        border-radius: 10px;
        color: white;
        margin-left: 10px;
        font-size: 15px;
    }
    .productpage .reviewbody {
        font-size: 12px;
        text-align: center;
    }

    .productpage .desc {
        width: 90%;
        max-width: 900px;
        align-self: center;
        text-align: center;
        font-size: 13px;
        font-weight: 300;
        color: var(--col2);
    }

    .productpage .rating1 {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 10px 0px;
    }
    .productpage .pc1 .c11 .imgbig {
        width: 70%;
        /* background-color: aqua; */
        display: flex;
        flex-direction: column;
        aspect-ratio: 1/1;
    }
}