.bannerslider {
    width: 100%;
    overflow: hidden;
    /* height: 60vh; */
    position: relative;
}

.bannerslider .imagecont {
    background-color: black;
    display: flex;
    position: relative;
    width: 100%;
    height: 60vh;
}

.bannerslider .imagecont img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bannerslider .imagecont .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.bannerslider .imagecont h1 {
    font-size: 2.0rem;
    font-weight: 200;
    margin-bottom: 5px;
    width: 70%;
}

.bannerslider .imagecont span {
    font-size: 1.3rem;
    font-weight: 100;
    margin-bottom: 5px;
    width: 70%;
}


.bannerslider .imagecont button {
    padding: 10px 20px;
    border: none;
    background-color: white;
    color: black;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.8s ease;
}

.bannerslider .imagecont button:hover {
    background-color: var(--col1);
    color: white;
}


@media (max-width: 768px) {
    .bannerslider .imagecont {
        height: 50vh;
    }

    .bannerslider .imagecont h1 {
        font-size: 1.5rem;
        width: 90%;
    }

    .bannerslider .imagecont span {
        font-size: 1rem;
        width: 90%;
    }
        
    .bannerslider .imagecont button {
        font-size: 1rem;
      }
}